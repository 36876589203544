{
  "c": "BÊTA",
  "calendar": {
    "addAppointment": "Ajouter un rendez-vous",
    "downloadChecklist": "Liste de contrôle",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Aucune expérience correspondante",
        "noExperiences": "Aucune expérience correspondante"
      }
    },
    "warnings": {
      "noExperiences": "Aucune expérience correspondante"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Actions rapides"
        }
      },
      "navAccount": {
        "help": {
          "label": "Aide"
        },
        "switch": {
          "label": "Changer pour"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "jour | jours",
    "hours": "heure | heures",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Aller à la vue d'ensemble",
        "description": "Votre compte ne dispose pas des droits nécessaires pour consulter cette page",
        "title": "Vous n’êtes pas autorisé à consulter cette page"
      },
      "serverError": {
        "cta": "Veuillez réessayer.",
        "description": "Suite à une erreur de serveur interne, nous n'avons pas pu traiter votre demande. Nous en avons été notifiés. Veuillez réessayer plus tard.",
        "title": "Désolé, une erreur s'est produite."
      },
      "walletManagement": {
        "noWalletErrorMessage": "Le fournisseur n’a aucun portefeuille activé"
      }
    },
    "validationErrors": {
      "invalidIban": "IBAN non valide",
      "notAValidEmail": "Le champ ne contient pas une adresse e-mail valide.",
      "notAValidMoneyAmount": "Montant non valide",
      "notEmpty": "Le champ ne peut pas être vide.",
      "notIdenticalIban": "Confirmer que l’IBAN doit être identique à l’IBAN",
      "unrecognisedDateTime": "Date et heure non reconnues"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Choisissez l’expérience à laquelle vous souhaitez attribuer une nouvelle disponibilité.",
      "title": "Ajouter un rendez-vous à l’expérience"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Actualiser",
      "title": "Une nouvelle version de bookingkit est disponible"
    }
  },
  "pagination": {
    "next": "Suivant",
    "previous": "Précédent"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Ajouter un rendez-vous",
    "disableHere": "Désactiver les raccourcis ici",
    "enableShortcuts": "Activer les raccourcis",
    "keyboardShortcuts": "Raccourcis clavier",
    "ok": "OK",
    "powerShortcuts": "Raccourcis clavier",
    "search": "Rechercher",
    "searchShortcutToastInfoMac": "Appuyez à nouveau sur ⌘ + F pour activer la recherche du navigateur natif",
    "searchShortcutToastInfoWindows": "Appuyez à nouveau sur Ctrl + F pour activer la recherche du navigateur natif",
    "toastMessage": "Les raccourcis sont activés par défaut.",
    "vendorShortcutToastInfoMac": "Appuyez à nouveau sur ⌘ + M pour activer le raccourci du navigateur natif",
    "vendorShortcutToastInfoWindows": "Appuyez à nouveau sur Ctrl + M pour activer le raccourci du navigateur natif",
    "vendorSwitch": "Transfert du fournisseur"
  },
  "search": {
    "accepted": "ACCEPTÉ",
    "archived": "ARCHIVÉ",
    "canceled": "ANNULÉ",
    "coupon": "Coupon",
    "declined": "REFUSÉ",
    "expired": "EXPIRÉ",
    "failure": "ÉCHEC",
    "feature": "Caractéristique",
    "flexibleTicket": "Billet modifiable",
    "invalid": "Non valide",
    "noResultFound": "Aucun résultat trouvé",
    "noResultFoundMessage": "Votre recherche ne correspond à aucun résultat. Veuillez essayer d'une autre façon.",
    "open": "OUVERT",
    "order": "Commande",
    "pending": "EN ATTENTE",
    "reserved": "RÉSERVÉ",
    "search": "Rechercher",
    "searchEntryMessage": "Vous pouvez rechercher :",
    "searchError": "Une erreur est survenue.",
    "searchErrorMessage": "Nous cherchons à corriger le problème. Veuillez réessayer plus tard.",
    "searchFeatureEntryMessage": "Caractéristiques par noms.",
    "searchOrderEntryMessage": "commandes par identifiant, client ou autres données liées aux commandes ;",
    "searchScopes": {
      "all": "Tous",
      "coupons": "Coupons",
      "features": "Caractéristiques",
      "flexibleTickets": "Offres flexibles",
      "orders": "Commandes",
      "vouchers": "Bons cadeaux"
    },
    "searchVoucherEntryMesaage": "Bons d'achat par code, titre ou description ;",
    "searchVoucherEntryMessage": "bons d'achat par code, titre ou description ;",
    "ticketTypes": {
      "all": "Tous",
      "coupons": "Coupons",
      "features": "Caractéristiques",
      "flexibleTickets": "Offres flexibles",
      "orders": "Commandes",
      "vouchers": "Bons cadeaux"
    },
    "valid": "Valide",
    "voucher": "Bon d'achat"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Données de l’entreprise"
      },
      "contract": {
        "label": "Contrat"
      },
      "main": {
        "label": "compte"
      },
      "profile": {
        "label": "Profil"
      },
      "signOut": {
        "label": "Se déconnecter"
      },
      "userManagement": {
        "label": "Gestion des utilisateurs"
      },
      "vendorSwitcher": {
        "label": "sélecteur de fournisseur"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "Paiements API"
      },
      "applicaitonLimits": {
        "label": "Limites d'application"
      },
      "application": {
        "label": "Application"
      },
      "applicationLimits": {
        "label": "Limites d'application"
      },
      "changeRates": {
        "label": "Changer les tarifs"
      },
      "chargeVouchers": {
        "label": "Facturer des bons d'achat"
      },
      "claimAccount": {
        "label": "Attribuer le compte"
      },
      "createAccount": {
        "label": "Créer un compte"
      },
      "dashboard": {
        "label": "Tableau de bord"
      },
      "disableEventDates": {
        "label": "Désactiver les dates d'événement"
      },
      "emailConfiguration": {
        "label": "Configuration des e-mails"
      },
      "importCoupons": {
        "label": "Importer des coupons"
      },
      "importOrders": {
        "label": "Importer des commandes"
      },
      "importVouchers": {
        "label": "Importer des bons d'achat"
      },
      "inviteNew": {
        "label": "Inviter un nouveau revendeur"
      },
      "invoices": {
        "label": "Factures"
      },
      "KYCUnverified": {
        "label": "Processus de connaissance du client non vérifié"
      },
      "main": {
        "label": "Administrateur"
      },
      "manageAPI": {
        "label": "Gérer l'API"
      },
      "manageBkUsers": {
        "label": "Gérer les utilisateurs bk"
      },
      "mangopayDisputes": {
        "label": "Litiges Mangopay"
      },
      "marketingPartners": {
        "label": "Partenaires marketing"
      },
      "outstandingPayments": {
        "label": "Paiements en souffrance"
      },
      "paymentRequests": {
        "label": "Demandes de paiement"
      },
      "payoutChannels": {
        "label": "Canaux de paiement"
      },
      "paypalPaymentRequests": {
        "label": "Demandes de paiements PayPal"
      },
      "platforms": {
        "label": "Plates-formes"
      },
      "provider": {
        "label": "Fournisseur"
      },
      "rates": {
        "label": "Tarifs"
      },
      "resellerPaymentStatus": {
        "label": "Statut du paiement du revendeur"
      },
      "setupFee": {
        "label": "Frais de configuration"
      },
      "translationTool": {
        "label": "Outil de traduction"
      },
      "unknownPaymentrequests": {
        "label": "Demandes de paiements inconnus"
      }
    },
    "beta": "BÊTA",
    "calendar": {
      "day": {
        "label": "Vue par jour"
      },
      "feed": {
        "label": "Flux de calendrier"
      },
      "main": {
        "label": "Calendrier"
      },
      "month": {
        "label": "Vue par mois"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Lots"
      },
      "experiences": {
        "label": "Expériences"
      },
      "flexibleOffers": {
        "label": "Offres modifiables"
      },
      "main": {
        "label": "Inventaire"
      },
      "products": {
        "label": "Produits"
      },
      "resources": {
        "label": "Gestion des ressources"
      }
    },
    "marketplace": {
      "main": {
        "label": "Application Marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Offres flexibles"
      },
      "main": {
        "label": "Commandes"
      },
      "orders": {
        "label": "Commandes"
      },
      "participants": {
        "label": "Participants"
      },
      "promotionalCoupons": {
        "label": "Codes promotionnels"
      },
      "requests": {
        "label": "Demandes"
      },
      "vouchers": {
        "label": "Bons cadeaux"
      }
    },
    "overview": {
      "main": {
        "label": "Aperçu"
      }
    },
    "reach": {
      "affiliateLinks": {
        "label": "Liens d’affiliation"
      },
      "googleThingsToDo": {
        "label": "Google Choses à faire"
      },
      "inviteNew": {
        "label": "Inviter un nouveau revendeur"
      },
      "main": {
        "label": "Reach"
      },
      "marketplace": {
        "label": "Marché"
      },
      "myPartners": {
        "label": "Mes partenaires"
      },
      "partnerRates": {
        "label": "Tarifs partenaires"
      },
      "platforms": {
        "label": "Plates-formes"
      },
      "promote": {
        "label": "Promouvoir les expériences locales"
      },
      "reachOrders": {
        "label": "Commandes"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Statistiques agrégées"
      },
      "exports": {
        "label": "Exportations"
      },
      "main": {
        "label": "Rapports"
      },
      "monthlyStatement": {
        "label": "Relevé mensuel"
      },
      "statements": {
        "label": "Factures"
      },
      "statistics": {
        "label": "Statistiques"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Revendeurs"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Mes partenaires"
      },
      "orders": {
        "label": "Commandes"
      },
      "platforms": {
        "label": "Intégrations des revendeurs"
      },
      "promote": {
        "label": "Promouvoir les expériences locales"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Configuration du checkout"
      },
      "emailCampaigns": {
        "label": "Campagnes par e-mail"
      },
      "localExperiences": {
        "label": "Expériences locales"
      },
      "main": {
        "label": "Vendre"
      },
      "paymentOptions": {
        "label": "Options de paiement"
      },
      "paymentProviders": {
        "label": "Prestataires de services de paiement"
      },
      "widgets": {
        "label": "Widgets"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Flux de calendrier"
      },
      "emailConfiguration": {
        "label": "Configuration des e-mails"
      },
      "invoiceLayout": {
        "label": "Mise en page de la facture"
      },
      "invoiceNNumber": {
        "label": "Numéro de facture"
      },
      "invoiceNumber": {
        "label": "Numéro de facture"
      },
      "main": {
        "label": "Paramètres"
      },
      "modules": {
        "label": "Modules"
      },
      "ticketConfiguration": {
        "label": "Configuration du billet"
      },
      "translations": {
        "label": "Traductions"
      },
      "vat": {
        "label": "TVA"
      },
      "voucherConfiguration": {
        "label": "Configuration des bons cadeaux"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "Application Marketplace"
      },
      "appointment": {
        "label": "Ajouter un rendez-vous"
      },
      "booking": {
        "label": "Réservation"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit Reach"
      },
      "contactSupport": {
        "label": "Contacter l'assistance"
      },
      "copyToClipboard": {
        "failure": "Echec de la copie du lien pour le fournisseur",
        "success": "Lien spécifique copié pour le fournisseur :"
      },
      "date": {
        "label": "Date"
      },
      "experience": {
        "label": "Expérience"
      },
      "knowledgeBase": {
        "label": "Base de connaissances",
        "url": "https://help.bookingkit.com/"
      },
      "onboardingWizard": {
        "label": "Assistant d'intégration (à confirmer)"
      },
      "powerShortcuts": {
        "label": "Raccourcis clavier"
      }
    },
    "updates": {
      "main": {
        "label": "Mises à jour du produit"
      },
      "mainBadge": {
        "label": "Nouveau"
      }
    },
    "wallet": {
      "main": {
        "label": "Portefeuille"
      },
      "partnerPayments": {
        "label": "Paiements partenaires"
      },
      "verification": {
        "label": "Vérification"
      },
      "walletManagement": {
        "label": "Gestion du portefeuille"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Fournisseurs disponibles",
    "dismiss": "Rejeter",
    "navigate": "Naviguer",
    "noResult": "Aucun résultat trouvé",
    "recentUsed": "Récemment utilisé",
    "resultsLabel": "Fournisseurs trouvés",
    "searchError": "Une erreur est survenue.",
    "searchErrorMessage": "Nous cherchons à corriger le problème. Veuillez réessayer plus tard.",
    "searchForVendor": "Rechercher le fournisseur",
    "select": "Sélectionner",
    "storeResultsLabel": "Fournisseurs disponibles",
    "supplierAlreadyInUseError": "Vous utilisez actuellement ce compte fournisseur.",
    "switchVendor": "Changer de fournisseur",
    "wishmorning": "Bonjour"
  }
}